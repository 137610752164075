.bubbles .bubble-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.bubbles .bubble-container {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  background-color: #333333;
}
.bubbles .bubble-container .background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  filter: blur(10px) brightness(0.6);
}
.bubbles .bubble-container .bubble {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  color: #fff;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  margin: 0;
}
.bubbles .bubble-container .bubble .bubble-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
}
.bubbles .bubble-container .bubble .bubble-content {
  display: flex;
  flex-direction: column;
}
.bubbles .bubble-container .bubble .bubble-content .bubble-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0;
}
.bubbles .bubble-container .bubble .bubble-content .bubble-description {
  color: #ccc;
}
.bubbles .loader {
  width: 100%;
  height: 100px;
  display: block;
  margin: auto;
  position: relative;
  background: #181818;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
  opacity: 0.5;
}
.bubbles .loader::after {
  content: "";
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  top: 15px;
  left: 15px;
  position: absolute;
  background-image: linear-gradient(100deg, transparent, rgba(24, 24, 24, 0.5) 50%, transparent 80%), radial-gradient(circle 28px at 28px 28px, #333333 99%, transparent 0), linear-gradient(#333333 20px, transparent 0), linear-gradient(#333333 18px, transparent 0);
  background-repeat: no-repeat;
  background-size: 75px 130px, 60px 60px, 260px 30px, 260px 20px, 290px 56px;
  background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}
@keyframes animloader {
  0% {
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
  100% {
    background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
  }
}