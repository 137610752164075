.feed-item {
  --space-between-items: 15px;
  --secondary-color: #94928e;
  --secondary-color-2: #797878;
  --secondary-bg-color: #181818;
  --card-padding: 15px;
  --card-border-radius: 15px;
  --profile-img-border-radius: 50%;
  --profile-img-width: 40px;
  --profile-img-height: 40px;
  --icon-size: 1rem;
  --username-font-size: 0.88rem;
  --username-letter-spacing: 0.04rem;
  --username-line-height: 0.7;
  --username-margin-right: 0.7rem;
  --time-since-font-size: 0.88rem;
  --time-since-line-height: 0.6;
  --bg-blur: 15px;
  --bg-brightness: 0.5;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding) 0;
  background: var(--secondary-bg-color);
  margin: var(--space-between-items) 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
}
.feed-item .bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  filter: blur(var(--bg-blur)) brightness(var(--bg-brightness));
  -webkit-filter: blur(var(--bg-blur)) brightness(var(--bg-brightness));
  -moz-filter: blur(var(--bg-blur)) brightness(var(--bg-brightness));
  -o-filter: blur(var(--bg-blur)) brightness(var(--bg-brightness));
  transform: translateZ(0);
  will-change: transform;
}
.feed-item .bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.feed-item .content {
  position: relative;
  z-index: 10;
}
.feed-item .left {
  padding: 0 var(--card-padding);
  font-size: 0.8rem;
  text-align: center;
  font-weight: 300;
}
.feed-item .left .icon {
  font-size: var(--icon-size);
}
.feed-item .right {
  padding: 0 var(--card-padding) 0 0;
}
.feed-item .ratio {
  border-radius: var(--profile-img-border-radius);
  overflow: hidden;
}
.feed-item .ratio:has(img) {
  width: var(--profile-img-width);
  height: var(--profile-img-height);
}
.feed-item .ratio img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.feed-item .username {
  font-family: "Semplicita Pro", sans-serif;
  letter-spacing: var(--username-letter-spacing);
  font-size: var(--username-font-size);
  font-weight: 500;
  line-height: var(--username-line-height);
  vertical-align: top;
  margin-right: var(--username-margin-right);
}
.feed-item .time-since {
  font-family: "Semplicita Pro", sans-serif;
  font-size: var(--time-since-font-size);
  font-weight: 300;
  line-height: var(--time-since-line-height);
  vertical-align: top;
  color: #ccc;
}
.feed-item .media {
  width: 100%;
  overflow: hidden;
  border-radius: var(--card-border-radius);
  max-height: max(40rem, 50vw);
  max-width: max(40rem, 50vw);
  display: flex;
  justify-content: center;
  align-items: center;
  /* Centers content within the container */
}
.feed-item .media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  /* Centers the image both horizontally and vertically */
}
.feed-item .media video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  /* Centers the image both horizontally and vertically */
}
.feed-item .modal-content {
  background-color: rgba(0, 0, 0, 0.8);
}
.feed-item .modal-body {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feed-item .modal-body .img {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}
.feed-item .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}
.feed-item p {
  font-size: 0.88rem;
}