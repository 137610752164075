@media (max-width: 768px) {
  .modal-dialog {
    width: 100vw;
    max-width: none;
    height: 100vh;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-header,
.modal-footer {
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}