.bubble {
  margin: 1.5rem;
}
.bubble .bubble-header-container {
  margin: 1.5rem 0;
}
.bubble .bubble-header-container .bubble-header {
  background-color: #181818;
  color: #fff;
  padding: 1rem;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.bubble .bubble-header-container .bubble-header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(85, 85, 85, 0));
  z-index: 1;
}
.bubble .bubble-header-container .bubble-header .background img {
  position: relative;
  background-size: cover;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.bubble .bubble-header-container .bubble-header h1 {
  position: relative;
  margin: 0;
  z-index: 2;
}
.bubble .bubble-header-container .bubble-interaction {
  display: block;
  text-align: center;
}
.bubble .bubble-header-container .bubble-interaction .label {
  font-size: 0.6rem;
}