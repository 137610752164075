@font-face {
    font-family: 'Semplicita Pro';
    src: url('SemplicitaPro-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Semplicita Pro';
    src: url('SemplicitaPro-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Semplicita Pro';
    src: url('SemplicitaPro-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Semplicita Pro';
    src: url('SemplicitaPro-Semibold.otf') format('opentype'); /* Corrected casing */
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Semplicita Pro';
    src: url('SemplicitaPro-SemiboldItalic.otf') format('opentype'); /* Corrected casing */
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Semplicita Pro';
    src: url('SemplicitaPro-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Semplicita Pro';
    src: url('SemplicitaPro-Italic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Semplicita Pro';
    src: url('SemplicitaPro.otf') format('opentype'); /* Adjusted if this is the Regular font */
    font-weight: normal;
    font-style: normal;
}
