.ra-btn {
  --base-color: #ffc333;
  --base-color-rgb: 255, 195, 51;
  --border-color-dark: #332400;
  --shadow-thickness: 3px;
  --transition-time: 0.22s;
  --button-font-size: 0.9rem;
  --button-status-msg-font-size: 0.72rem;
  --button-status-msg-top: 0;
  --button-margin-bottom: 0.3rem;
  --button-border-radius: 15px;
  --button-padding: 10px 20px 10px 20px;
  --small-button-padding: 7.5px 15px 7.5px 15px;
  --big-button-padding: 12px 24px 12px 24px;
  --small-button-font-size: 0.675rem;
  --small-button-status-msg-font-size: 0.675rem;
  --small-button-status-msg-top: 0;
  --small-button-margin-bottom: 0.225rem;
  --big-button-font-size: 1.08rem;
  --big-button-status-msg-font-size: 0.648rem;
  --big-button-status-msg-top: 0;
  --big-button-margin-bottom: 0.36rem;
  --button-min-width: 100px;
  --button-font-family: Open Sans;
  --button-font-weight: 500;
  --button-bg-opacity: 0.12;
  --letter-spacing: 0.088rem;
  --border-width-thin: 1px;
  --outer-border-width: 1px;
  background: transparent;
  border: 0;
  padding: 0;
  padding-bottom: var(--shadow-thickness);
  transition: all var(--transition-time);
  margin-bottom: var(--button-margin-bottom);
  text-align: center;
}
.ra-btn.big {
  margin-bottom: var(--big-button-margin-bottom);
}
.ra-btn.small {
  margin-bottom: var(--small-button-margin-bottom);
}
.ra-btn.small.square {
  height: 60px !important;
  width: 60px !important;
}
.ra-btn.ghost .inner {
  background: rgba(var(--base-color-rgb), 0.2);
}
.ra-btn .outer {
  border: var(--outer-border-width) solid var(--border-color-dark);
  border-radius: var(--button-border-radius);
  width: fit-content;
  padding-bottom: 3px;
  transition: all var(--transition-time);
  margin-bottom: 0px;
}
.ra-btn .inner {
  position: relative;
  text-transform: uppercase;
  font-weight: var(--button-font-weight);
  letter-spacing: var(--letter-spacing);
  border: var(--border-width-thin) solid var(--base-color);
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding: var(--button-padding);
  font-size: var(--button-font-size);
  font-family: var(--button-font-family), sans-serif;
  color: var(--base-color);
  cursor: pointer;
  transition: all var(--transition-time);
  box-shadow: 0 var(--shadow-thickness) 0 0 var(--base-color);
  border-radius: var(--button-border-radius);
}
.ra-btn.small .inner {
  padding: var(--small-button-padding);
  font-size: var(--small-button-font-size);
  line-height: 1.05;
}
.ra-btn.small .inner .ra-btn-label.text-xxs {
  font-size: calc(var(--small-button-font-size) * 0.72);
}
.ra-btn.small .inner .ra-btn-label.text-xs {
  font-size: calc(var(--small-button-font-size) * 0.8);
}
.ra-btn.small .inner .ra-btn-label.text-sm {
  font-size: calc(var(--small-button-font-size) * 0.9);
}
.ra-btn.small .inner .ra-btn-label.text-md {
  font-size: calc(var(--small-button-font-size) * 1);
}
.ra-btn.small .inner .ra-btn-label.text-lg {
  font-size: calc(var(--small-button-font-size) * 1.125);
}
.ra-btn.small .inner .ra-btn-label.text-xl {
  font-size: calc(var(--small-button-font-size) * 1.25);
}
.ra-btn.small .inner .ra-btn-label.text-xxl {
  font-size: calc(var(--small-button-font-size) * 1.5);
}
.ra-btn.big .inner {
  padding: var(--big-button-padding);
  font-size: var(--big-button-font-size);
}
.ra-btn.ghost:hover .inner {
  background: rgba(var(--base-color-rgb), 1);
  color: var(--border-color-dark);
  box-shadow: 0 0 0 0 var(--base-color);
}
.ra-btn:hover .inner {
  background: rgba(var(--base-color-rgb), 1);
  color: var(--border-color-dark);
  box-shadow: 0 0 0 0 var(--base-color);
}
.ra-btn:not(.active):hover .outer {
  margin-bottom: var(--shadow-thickness);
}
.ra-btn:hover .outer {
  padding-bottom: 0;
}
.ra-btn:hover {
  margin-top: var(--shadow-thickness);
  padding-bottom: 0;
}
.ra-btn.ghost.active .inner {
  background: rgba(var(--base-color-rgb), 1);
  color: var(--border-color-dark);
  box-shadow: 0 0 0 0 var(--base-color);
}
.ra-btn.active .inner {
  background: rgba(var(--base-color-rgb), 1);
  color: var(--border-color-dark);
  box-shadow: 0 0 0 0 var(--base-color);
}
.ra-btn.active .outer {
  padding-bottom: 0;
  margin-bottom: var(--shadow-thickness);
}
.ra-btn:disabled, .ra-btn:disabled .outer, .ra-btn:disabled .inner {
  cursor: not-allowed;
}
.ra-btn .ra-status-message {
  text-transform: none;
  letter-spacing: normal;
  width: 100%;
  min-height: 100%;
  border-radius: var(--button-border-radius);
  font-size: var(--button-status-msg-font-size);
  padding: 0.5rem 1rem;
  position: absolute;
  top: var(--button-status-msg-top);
  background: var(--base-color);
  outline: 1px solid var(--base-color);
  display: flex;
  align-items: center;
  text-align: left;
}
.ra-btn .ra-status-message .ra-icon {
  padding-right: 1rem;
}
.ra-btn.big .ra-status-message {
  font-size: var(--big-button-status-msg-font-size);
  top: var(--big-button-status-msg-top);
}
.ra-btn.small .ra-status-message {
  font-size: var(--small-button-status-msg-font-size);
  top: var(--small-button-status-msg-top);
}

.white {
  --base-color: #fff;
  --base-color-rgb: 255, 255, 255;
  --border-color-dark: #1a1a1a;
}
.white .inner {
  border-color: var(--base-color);
  color: var(--base-color);
  box-shadow: 0 var(--shadow-thickness) 0 0 var(--base-color);
}
.white:hover .inner {
  background: rgba(var(--base-color-rgb), 1);
  color: var(--border-color-dark);
  box-shadow: 0 0 0 0 var(--base-color);
}

.primary {
  --base-color: #ffc333;
  --base-color-rgb: 255, 195, 51;
  --border-color-dark: black;
}
.primary .inner {
  border-color: var(--base-color);
  color: var(--base-color);
  box-shadow: 0 var(--shadow-thickness) 0 0 var(--base-color);
}
.primary:hover .inner {
  background: rgba(var(--base-color-rgb), 1);
  color: var(--border-color-dark);
  box-shadow: 0 0 0 0 var(--base-color);
}

.secondary {
  --base-color: #757371;
  --base-color-rgb: 117, 115, 113;
  --border-color-dark: black;
}
.secondary .inner {
  border-color: var(--base-color);
  color: var(--base-color);
  box-shadow: 0 var(--shadow-thickness) 0 0 var(--base-color);
}
.secondary:hover .inner {
  background: rgba(var(--base-color-rgb), 1);
  color: var(--border-color-dark);
  box-shadow: 0 0 0 0 var(--base-color);
}

.success {
  --base-color: #19d45a;
  --base-color-rgb: 25, 212, 90;
  --border-color-dark: black;
}
.success .inner {
  border-color: var(--base-color);
  color: var(--base-color);
  box-shadow: 0 var(--shadow-thickness) 0 0 var(--base-color);
}
.success:hover .inner {
  background: rgba(var(--base-color-rgb), 1);
  color: var(--border-color-dark);
  box-shadow: 0 0 0 0 var(--base-color);
}

.error {
  --base-color: #d30000;
  --base-color-rgb: 211, 0, 0;
  --border-color-dark: black;
}
.error .inner {
  border-color: var(--base-color);
  color: var(--base-color);
  box-shadow: 0 var(--shadow-thickness) 0 0 var(--base-color);
}
.error:hover .inner {
  background: rgba(var(--base-color-rgb), 1);
  color: var(--border-color-dark);
  box-shadow: 0 0 0 0 var(--base-color);
}

.info {
  --base-color: #6352ff;
  --base-color-rgb: 99, 82, 255;
  --border-color-dark: black;
}
.info .inner {
  border-color: var(--base-color);
  color: var(--base-color);
  box-shadow: 0 var(--shadow-thickness) 0 0 var(--base-color);
}
.info:hover .inner {
  background: rgba(var(--base-color-rgb), 1);
  color: var(--border-color-dark);
  box-shadow: 0 0 0 0 var(--base-color);
}

.warning {
  --base-color: #ff8800;
  --base-color-rgb: 255, 136, 0;
  --border-color-dark: black;
}
.warning .inner {
  border-color: var(--base-color);
  color: var(--base-color);
  box-shadow: 0 var(--shadow-thickness) 0 0 var(--base-color);
}
.warning:hover .inner {
  background: rgba(var(--base-color-rgb), 1);
  color: var(--border-color-dark);
  box-shadow: 0 0 0 0 var(--base-color);
}

.ra-btn-label {
  visibility: visible;
  text-wrap: nowrap;
}
.ra-btn-label.horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.ra-btn-label.horizontal svg {
  margin-right: 0.5rem;
}

.ra-icon {
  visibility: hidden;
}
.ra-icon.absolute {
  position: absolute;
}

.ra-icon.inline {
  position: relative;
  margin-right: 0.5rem;
}

.ra-btn .loading-icon {
  visibility: visible;
}

.ra-btn .error-icon {
  visibility: visible;
}

.ra-btn .success-icon {
  visibility: visible;
}

.ra-btn .loading-icon ~ .ra-btn-label,
.ra-btn .error-icon ~ .ra-btn-label,
.ra-btn .success-icon ~ .ra-btn-label {
  visibility: hidden;
}

.icon-button {
  width: 3.33rem;
  height: 3.33rem;
  background-color: #333333;
  border-radius: 50%;
  color: #7A7875;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}