/* = Bootstrap Overrides */

:root {
	--ra-rounded: 0.5rem;
}

.ms-n1 {
	margin-left: -0.25rem !important;
}

.ms-n2 {
	margin-left: -0.5rem !important;
}

.ms-n3 {
	margin-left: -1rem !important;
}

.ms-n4 {
	margin-left: -1.5rem !important;
}

.ms-n5 {
	margin-left: -3rem !important;
}

.lh-2 {
	line-height: 1.2 !important;
}
.lh-3 {
	line-height: 1.4 !important;
}
.lh-4 {
	line-height: 1.6 !important;
}
.lh-5 {
	line-height: 1.8 !important;
}

/* = Text Size */
.text-xxs {
	font-size: 0.72rem;
}
.text-xs {
	font-size: 0.8rem;
}
.text-sm {
	font-size: 0.9rem;
}
.text-md {
	font-size: 1rem;
}
.text-lg {
	font-size: 1.125rem;
}
.text-xl {
	font-size: 1.25rem;
}
.text-xxl {
	font-size: 1.5rem;
}

.border-gray-1 {
	border-color: #242424 !important;
}

.bg-gray-1 {
	background-color: #111111 !important;
}
.bg-gray-2 {
	background-color: #181818 !important;
}
.bg-gray-3 {
	background-color: #2b2b2b !important;
}
.bg-gray-4 {
	background-color: #313131 !important;
}

/* = Line Cropping */

.crop-text-1 {
	-webkit-line-clamp: 1;
	line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
}

.crop-text-2 {
	-webkit-line-clamp: 2;
	line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
}
.crop-text-3 {
	-webkit-line-clamp: 3;
	line-clamp: 3;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
}

.ra-rounded {
	border-radius: var(--ra-rounded);
}
