@charset "UTF-8";
.profile {
  margin-top: 1.5rem;
}
.profile .displayname {
  margin-bottom: 0;
}
.profile .user-img {
  aspect-ratio: 1/1;
  overflow: hidden;
  height: 80px;
}
.profile .user-img img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile .user-links .link:not(:first-child)::before {
  content: "";
  margin-left: 8px;
  /* Space between the text and the dot */
}
.profile .user-links .link:not(:last-child)::after {
  content: "•";
  /* Add the middle dot */
  margin-left: 8px;
  /* Space between the text and the dot */
  line-height: 1;
  color: #ccc;
  /* Optional: Adjust the color of the dot */
}
.profile .user-stats .stat:not(:first-child)::before {
  content: "";
  margin-left: 8px;
}
.profile .user-stats .stat:not(:last-child)::after {
  content: "•";
  margin-left: 8px;
  line-height: 1;
  color: #ccc;
}
.profile .card {
  padding: 1rem;
  border: none;
  border-radius: 15px;
  margin-bottom: 1.3rem;
  background-color: #181818;
}