@import "assets/fonts/SemplicitaPro/font.css";
@import "assets/fonts/OpenSans/font.css";
#root {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
}

body {
  display: flex;
  justify-content: center;
  transition: all 0.5s;
  color: #fff;
  background-color: #000;
  font-size: clamp(0.875rem, calc(0.675rem + 0.8vw), 0.95rem);
}

p {
  font-weight: 300;
  font-size: clamp(0.875rem, calc(0.675rem + 0.8vw), 0.95rem);
}

a {
  color: #ffc333 !important;
  text-decoration: none !important;
}
a:hover:not(.no-deco) {
  text-decoration: underline !important;
}
a:hover:not(.no-deco) * {
  text-decoration: none !important;
}
a.btn {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Semplicita Pro", sans-serif;
  line-height: normal;
}

h1,
.h1 {
  margin-bottom: clamp(0.5rem, calc(0.5rem + 1.5vw), 1.4rem);
  font-size: clamp(1.4rem, calc(0.9rem + 2vw), 3rem);
  font-weight: 700;
  letter-spacing: normal;
}

h2,
.h2 {
  margin-bottom: clamp(0.4rem, calc(0.5rem + 1.2vw), 1.3em);
  font-size: clamp(1.3rem, calc(0.8rem + 1.8vw), 2.5rem);
  font-weight: 700;
  letter-spacing: normal;
}

h3,
.h3 {
  margin-bottom: clamp(0.3rem, calc(0.5rem + 1vw), 1.2rem);
  font-size: clamp(1.2rem, calc(0.7rem + 1.5vw), 2rem);
  font-weight: 700;
  letter-spacing: normal;
}

h4,
.h4 {
  margin-bottom: clamp(0.25rem, calc(0.5rem + 0.9vw), 1rem);
  font-size: clamp(1.1rem, calc(0.6rem + 1.2vw), 1.75rem);
  font-weight: 700;
  letter-spacing: normal;
}

h5,
.h5 {
  margin-bottom: clamp(0.2rem, calc(0.5rem + 0.7vw), 0.7rem);
  font-size: clamp(1rem, calc(0.5rem + 1vw), 1.5rem);
  font-weight: 700;
  letter-spacing: normal;
}

h6,
.h6 {
  margin-bottom: clamp(0.15rem, calc(0.5rem + 0.6vw), 0.6rem);
  font-size: clamp(0.9rem, calc(0.4rem + 0.8vw), 1.25rem);
  font-weight: 700;
  letter-spacing: normal;
}

.btn {
  border: 0px solid transparent !important;
  border-radius: 0 !important;
}
.btn.bottom {
  border-top: 1px solid transparent !important;
  border-radius: 0 !important;
}
.btn.bottom.active {
  color: #ffc333 !important;
  border-top: 1px solid #ffc333 !important;
  cursor: pointer;
  /* background: transparent !important;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%) !important; */
}
.btn.top {
  border-bottom: 1px solid transparent !important;
  border-radius: 0 !important;
}
.btn.top.active {
  color: #ffc333 !important;
  border-bottom: 1px solid #ffc333 !important;
  cursor: pointer;
  /* background: transparent !important;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%) !important; */
}

.app-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
}
@media screen and (min-width: 640px) {
  .app-container {
    border-left: 1px solid;
    border-right: 1px solid;
    /* width: 640px; */
  }
}

.badge {
  line-height: 1.2 !important;
}
.badge.badge-outline {
  background-color: transparent !important;
  border: 1px solid #fff;
}

textarea {
  background-color: var(--black-300);
}

.timeline-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.nav-indicator-container {
  z-index: 3;
  position: fixed;
  bottom: 30px;
  left: 100px;
  right: 100px;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.33rem;
}
.nav-indicator-container .nav-indicator {
  background: #333333;
  text-align: center;
  padding: 4px 20px 4px 20px;
  overflow: hidden;
  border-radius: 100px;
  transition: background-color 0.3s ease-in-out;
  color: #ccc;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  line-height: 1.6;
  word-break: keep-all;
  font-size: 0.8rem;
}