.page-wrapper {
  position: relative;
  overflow: hidden;
}

.page.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  border-radius: 15px;
  overflow: auto;
  z-index: 1;
  width: 100%;
  height: 100vh;
  will-change: transform, filter;
  /* No transitions needed, animations are handled by React Spring */
}
.page.bg.blurred {
  filter: brightness(0.5) blur(4px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  /* Or add a semi-transparent background if desired */
  z-index: 990;
}

.page.fg {
  background: #111;
  margin-top: 2.5rem;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 0 20px black;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100vh - 2.5rem);
  min-height: 18.5vh;
  overflow-y: auto;
  z-index: 1000;
  touch-action: none;
  padding: 2.5rem 1.5rem 2.5rem 1.5rem;
}
.page.fg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 40px;
  height: 5px;
  background: #ccc;
  border-radius: 5px;
  transform: translateX(-50%);
  margin-top: 8px;
}