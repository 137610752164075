.input-group {
  --secondary-text: #7A7875;
  --secondary: #181818;
  --tertiary: #333333;
  color: var(--secondary-text);
  margin-bottom: 0.8rem;
  font-weight: 500;
  font-size: 0.9rem;
}
.input-group .input-group-text.icon {
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border-color: var(--tertiary);
  color: var(--secondary-text);
  background-color: var(--secondary);
  border-right: 0;
}
.input-group .form-floating input {
  border-left: 0;
  border-color: var(--tertiary);
  background-color: var(--secondary);
  transition: background-color 0.3s ease;
  border-radius: 15px;
  height: 3rem;
  min-height: 3rem;
}
.input-group .form-floating input:focus {
  background-color: var(--secondary);
  box-shadow: none;
  border-color: var(--tertiary);
}
.input-group .form-floating label {
  padding-left: 0;
  padding: 0.77rem 0rem;
}
.input-group .form-floating label::after {
  background-color: transparent !important;
}
.input-group .form-floating input:focus ~ label::after {
  background-color: transparent;
}