.welcome {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.welcome::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, #FFC333 0%, #73331F 100%);
  z-index: 0;
}
.welcome::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../assets/media/backgrounds/xs_pattern5.png") center/80px repeat;
  z-index: 2;
  mix-blend-mode: difference;
  -webkit-mix-blend-mode: difference;
  /* For older Safari versions */
}
.welcome .menu-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
.welcome .menu-container .ra-btn.back {
  margin: 1.3rem;
}
.welcome .menu-container .menu {
  min-height: 60vh;
  background: #111111;
  padding: 1.3rem;
  border-radius: 15px 15px 0 0;
}